<template>
    <LatestChatDropdown
        v-if="layout === 'dropdown'"
        :ping="handlePing"
        :conversationList="conversationList"
        :markAllLoader="markAllLoader"
        :showPreloader="showPreloader"
        @markAllAsRead="markAllAsRead"
        @clickConversation="conversationClickHandler"
        @viewAll="viewAll"
    ></LatestChatDropdown>
    <LatestChatConversations
        v-else
        :class=" isDemo && 'pointer-events-none'"
        :ping="handlePing"
        :conversationList="conversationList"
        :markAllLoader="markAllLoader"
        :showPreloader="showPreloader"
        @clickConversation="conversationClickHandler"
    ></LatestChatConversations>
</template>

<script setup>
import {CometChat} from "@cometchat-pro/chat";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useRouter} from "vue-router";
import {useWidgetDemoData} from "@/composables/useWidgetDemoData";
import {useChat as useChatStore} from "@/stores/ChatStore";

const {chatItemToOpen} = storeToRefs(useChatStore());

const {demoChatData: demoData} = useWidgetDemoData();

const router = useRouter();

import {
    DEFAULT_OBJECT_PROP,
    DEFAULT_STRING_PROP,
} from "@/components/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/resources/constants";
import {theme} from "@/components/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/resources/theme";
import {SvgAvatar} from "@/components/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/util/svgavatar";
import emitter from "@/plugins/mitt";
import LatestChatDropdown from "@/components/custom-cometchat/LatestChatDropdown.vue";
import LatestChatConversations from "@/components/custom-cometchat/LatestChatConversations.vue";
import {useAuth} from "../../stores/AuthStore";
import {useChat} from "../../composables/useChat";
import {toast} from "vue3-toastify";
import {storeToRefs} from "pinia";

const props = defineProps({
    type: {...DEFAULT_STRING_PROP},
    item: {...DEFAULT_OBJECT_PROP},
    theme: {...DEFAULT_OBJECT_PROP},
    config: {...DEFAULT_OBJECT_PROP},
    layout: {
        type: String,
        default: 'dropdown',
        required: true,
    },
    isDemo: {
        type: Boolean,
        default: false,
    },
})

const conversationList = ref([]);
const conversationsLoader = ref(false);

const markAllLoader = ref(false);

const open = ref(false);

const listenerID = `listener_${Date.now()}`;

function filterConversations(conversations) {
    if(props.layout === 'dropdown') {
        return conversations.filter(conversation => conversation.getUnreadMessageCount() > 0).slice(-5);
    }
    return conversations.slice(-5)
}

async function loadChatConversations() {
    try {
        const limit = 5;
        const conversationsRequest = new CometChat.ConversationsRequestBuilder()
            .setLimit(limit)
            .build();

        let conversations = await conversationsRequest.fetchNext();

        conversations = filterConversations(conversations);

        conversations.forEach((conversation) => {
            handleAvatar(conversation);

            if (
                props.type &&
                props.item &&
                props.type === conversation.conversationType
            ) {
                if (
                    (conversation.conversationType === "user" &&
                        props.item.uid === conversation.conversationWith.uid) ||
                    (conversation.conversationType === "group" &&
                        props.item.guid === conversation.conversationWith.guid)
                ) {
                    conversation.unreadMessageCount = 0;
                }
            }
        });
        return conversationList.value = [...conversations];
    } catch (error) {
        console.error("Error fetching conversations:", error);
    } finally {
        conversationsLoader.value = false;
    }
}

function handleAvatar(conversation) {
    if (
        conversation.conversationType === "user" &&
        !conversation.conversationWith.avatar
    ) {
        conversation.conversationWith.avatar = setAvatar(conversation);
    } else if (
        conversation.conversationType === "group" &&
        !conversation.conversationWith.icon
    ) {
        conversation.conversationWith.icon = setAvatar(conversation);
    }
}

function setAvatar(conversation) {
    if (
        conversation.conversationType === "user" &&
        !conversation.conversationWith.avatar
    ) {
        const uid = conversation.conversationWith.uid;
        const char = conversation.conversationWith.name.charAt(0).toUpperCase();

        return SvgAvatar.getAvatar(uid, char);
    } else if (
        conversation.conversationType === "group" &&
        !conversation.conversationWith.icon
    ) {
        const guid = conversation.conversationWith.guid;
        const char = conversation.conversationWith.name.charAt(0).toUpperCase();

        return SvgAvatar.getAvatar(guid, char);
    }
}

function conversationClickHandler(conversation) {
    let type = 'user'

    if (conversation.conversationType !== "user") {
        type = 'group'
    }

    const index = conversationList.value.findIndex(
        c => c.conversationId === conversation.conversationId
    );

    if(index !== -1) {
        conversationList.value.splice(index, 1);
    }

    if(router.currentRoute.value.name !== 'chat') {
        chatItemToOpen.value = {chatNotificationItem: JSON.stringify(conversation.conversationWith), chatNotificationType: type}

        router.push({name: 'chat'}).then(() => {
            open.value = false;
        })
    } else {
        emitter.emit('chat-notification-click', {chatNotificationItem: JSON.stringify(conversation.conversationWith), chatNotificationType: type})
    }
}

function addListener() {
    CometChat.addMessageListener(
        listenerID,
        new CometChat.MessageListener({
            onTextMessageReceived: onMessageReceived,
            onMediaMessageReceived: onMessageReceived,
            onGroupMessageReceived: onMessageReceived,
        })
    );
}

async function convertMessageToConversation(message) {
    return CometChat.CometChatHelper.getConversationFromMessage(message).then(
        conversation => {
            return conversation;
        }, error => {
            console.log("Error while converting message object", error);
        }
    );
}

async function onMessageReceived(message) {
    const conversation = await convertMessageToConversation(message);

    const index = conversationList.value.findIndex(
        c => c.conversationId === conversation.conversationId
    );

    let currentUreadMessageCount = 0;
    if(index !== -1) {
        currentUreadMessageCount = conversationList.value[index].unreadMessageCount
        conversationList.value.splice(index, 1);
    }

    handleAvatar(conversation)

    conversation.unreadMessageCount = currentUreadMessageCount + 1;

    conversationList.value.unshift(conversation);
}

async function markAllAsRead() {
    if(conversationList.value.length === 0) {
        return;
    }

    const conversationsRequest = new CometChat.ConversationsRequestBuilder()
        .setLimit(50)
        .build();

    try {
        markAllLoader.value = true;
        const auxConversationList = await conversationsRequest.fetchNext();

        const markReadPromises = auxConversationList.map(conversation => {
            const lastMessage = conversation.lastMessage;

            if (conversation.getConversationType() === CometChat.RECEIVER_TYPE.USER) {
                return CometChat.markAsRead(lastMessage).then(
                    () => {
                    }, error => {
                        toast.error("An error occurred when marking the message as read - " + error);
                    }
                );
            } else if (conversation.getConversationType() === CometChat.RECEIVER_TYPE.GROUP) {
                return CometChat.markAsRead(lastMessage).then(
                    () => {
                    }, error => {
                        toast.error("An error occurred when marking the message as read - " + error);
                    }
                );
            }
        });

        await Promise.all(markReadPromises);
    } catch (error) {
        console.log('Conversation fetching or marking as read failed with error:', error);
    } finally {
        conversationList.value = [];
        markAllLoader.value = false;

        emitter.emit('refresh-conversation-list')
    }
}

function viewAll() {
    open.value = false;
    router.push('/chat');
}

const themeValue = computed(() => {
    return Object.assign({}, theme, props.theme || {});
});

const showPreloader = computed(() => {
    return conversationsLoader.value && conversationList.value.length === 0
})

const handlePing = computed(() => {
    return conversationList.value.length > 0
})

onMounted(async () => {
    if(props.isDemo) {
        conversationList.value = demoData;
        return
    }

    const {chatToken} = storeToRefs(useAuth());
    const {doChatLogin} = useChat();

    conversationsLoader.value = true;
    doChatLogin(chatToken.value).then(() => {
        loadChatConversations();

        addListener();

        emitter.on('refresh-conversation-list', () => {
            loadChatConversations();
        })
    });
})

onUnmounted(() => {
    CometChat.removeMessageListener(listenerID);
})
</script>

<style scoped>

</style>
