import {defineStore} from "pinia";
import {ref} from "vue";

export const useChat = defineStore('useChat', () => {
    const lastActiveConversation = ref(null);

    const groupToBeCreated = ref(null);

    const chatItemToOpen = ref(null);

    const newChat = ref(false);

    function setLastActiveChatConversation(conversation) {
        lastActiveConversation.value = conversation;
    }

    function setGroupToBeCreatedData(payload) {
        groupToBeCreated.value = payload;
    }

    function setChatItemToOpen(notification) {
        chatItemToOpen.value = notification
    }

    function setNewChat(value) {
        newChat.value = value;
    }

    return {
        setLastActiveChatConversation,
        lastActiveConversation,
        groupToBeCreated,
        setGroupToBeCreatedData,
        chatItemToOpen,
        setChatItemToOpen,
        newChat,
        setNewChat,
    }
});