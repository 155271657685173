<template>
    <div
        class="w-full min-h-[585px] xxs:overflow-visible lg:overflow-y-scroll p-5 relative box-border bg-white rounded flex flex-col"
        :class="[conversationList.length > 8 ? '' : 'no-scrollbar']"
    >
        <div class="flex flex-row flex-wrap lg:flex-nowrap justify-between mb-3">
            <p class="text-body-3 text-default-1 font-medium">Chat</p>
            <router-link :to="{name: 'chat'}" class="flex flex-row items-center cursor-pointer" v-if="conversationList.length > 0">
                <p class="text-body-6 font-medium text-primary-1 mr-1.5">View all</p>
                <ArrowRight :color="color.hex"/>
            </router-link>
        </div>
        <div
            v-if="showPreloader"
            class="overflow-y-scroll no-scrollbar border-box px-1"
        >
            <div
                class="w-full h-[70px] skeleton-loader mt-1"
                v-for="i in 8"
                :key="i"
            ></div>
        </div>
        <div class="flex w-full flex-col @container"  v-if="conversationList.length > 0 && !showPreloader">
            <div v-for="(conversation, i) in conversationList" :key="i">
                <comet-chat-conversation-list-item
                    :config="config"
                    :theme="themeValue"
                    :conversationKey="conversation.id"
                    :conversation="conversation"
                    @click="emit('clickConversation', conversation)"
                />
            </div>
        </div>
        <div v-if="conversationList.length === 0 && !showPreloader" class="flex w-full justify-center items-center flex-col h-full text-center flex-grow">
            <NoMessages :color="color.hex" class="mt-8"/>
            <p class="mt-7 text-body-5 text-default-1">You do not have any new conversations</p>
        </div>
        <div class="flex-1 lg:flex"></div>
        <div v-if="!showPreloader" class="flex xxs:flex-col lg:flex-row">
            <Button button-type="primary" label="New chat" class="w-full mt-4" @click="newChat"/>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import CometChatConversationListItem
    from "@/components/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/components/Chats/CometChatConversationListItem/CometChatConversationListItem.vue";
import {DEFAULT_OBJECT_PROP} from "@/components/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/resources/constants";
import {theme} from "@/components/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/resources/theme";
import NoMessages from "@/components/icons/NoMessages.vue";
import {useTheme} from "@/stores/ThemeStore";
import Button from "@/components/core/Button.vue";
import router from '@/router';
import ArrowRight from "@/components/icons/navigation/ArrowRight.vue";
import {useChat as useChatStore} from "@/stores/ChatStore";

const {setNewChat} = useChatStore();

const props = defineProps({
    config: {...DEFAULT_OBJECT_PROP},
    conversationList: {
        type: Array,
        default: () => [],
        required: true,
    },
    showPreloader: {
        type: Boolean,
        default: false,
        required: true,
    },
});

const emit = defineEmits(["clickConversation"]);

const themeValue = computed(() => {
    return Object.assign({}, theme, props.theme || {});
});

const {color} = useTheme();

function newChat() {
    setNewChat(true);

    router.push({name: 'chat'})
}
</script>

<style scoped></style>